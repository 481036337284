import { motion } from "framer-motion";
import { useRef } from "react";
import './App.css';

function App() {
  const about = useRef();

  return (
    <div className="App">
      <motion.div
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        style={{ display: "flex", padding: ".25rem", justifyContent: "space-between", alignItems: "center" }}>
        <div
          style={{ display: "flex", gap: ".5rem", justifyContent: "space-between", alignItems: "center" }}
        >
          <motion.img
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100 }}

            height={30}
            style={{ borderRadius: "50%" }}
            src={"https://i.imgur.com/vl1sqkR.png"}>
          </motion.img>

          <motion.div
            initial={{ x: -200 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100 }}

            style={{ fontSize: "1rem" }}
            className="CasperHeader">
            Casper On Solana
          </motion.div>
        </div>

        <motion.div
          initial={{ x: 300 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}

          style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => window.open("https://t.me/+O1NODZkER6VjMGJh")}
            className="CasperButton">Telegram</button>
          <button
            onClick={() => window.open("https://x.com/deafcaspersol")}
            className="CasperButton">Twitter</button>
        </motion.div>
      </motion.div>

      <div style={{ height: "80dvh", alignContent: "center" }}>
        <motion.header
          initial={{ y: -100, zIndex: 1 }}
          animate={{ y: 0 }}
          className="CasperHeader">
          Casper
        </motion.header>

        <motion.img
          initial={{ y: 500, zIndex: 0 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          height={"350px"}
          className="CasperImage"
          src={"https://i.imgur.com/vl1sqkR.png"}></motion.img>

        <motion.div
          initial={{ y: 500, zIndex: 0 }}
          animate={{ y: 0 }}

          className="CasperDescription">
          The first deaf dog on Solana!
          <hr style={{ width: "100%" }}></hr>

          <div style={{ display: "grid", alignText: "center" }}>
            <div>Contract</div>
            <div>
              HMP7jQEzWif9rVANND8MXhMSXhiJKeZRQnfM7aofpump
            </div>
          </div>
        </motion.div>
      </div>

      <div style={{ display: "grid", justifyContent: "center", paddingBottom: "10rem" }}>
        <motion.button
          className="MeetCasperButton"
          onClick={() => about.current.scrollIntoView({ behavior: "smooth" })}>
          Meet Casper
        </motion.button>
      </div>

      <motion.header
        ref={about}
        initial={{ y: -100, zIndex: 1 }}
        animate={{ y: 0 }}
        className="CasperHeader">
        Who Is Casper
      </motion.header>

      <motion.div
        initial={{ y: 500, zIndex: 0 }}
        animate={{ y: 0 }}

        className="CasperDescription">
        Casper is a deaf american Pit-bull. I am currently 3 years old.
      </motion.div>

      <motion.div
        initial={{ y: 500, zIndex: 0 }}
        animate={{ y: 0 }}

        className="CasperDescription">
        I like ropes, bones & beggin' strips!
      </motion.div>

      <motion.div
        initial={{ y: 500, zIndex: 0 }}
        animate={{ y: 0 }}

        className="CasperDescription">
        I like fun car trips with my dad.
      </motion.div>
    </div >
  );
}

export default App;
